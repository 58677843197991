@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

@font-face {
  font-family: 'TTNorms';
  src: local('TTNorms'), url('./assets/fonts/TTNorms/TTNorms-Regular.otf') format('opentype');
  font-weight: 400;
}
@font-face {
  font-family: 'TTNorms';
  src: local('TTNorms'), url('./assets/fonts/TTNorms/TTNorms-Medium.otf') format('opentype');
  font-weight: 500;
}

body {
  margin: 0;
  font-family: 'Rubik', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  width: 14px;
  height: 18px;
}
::-webkit-scrollbar-thumb {
  height: 6px;
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  -webkit-border-radius: 7px;
  border-radius: 7px;
  background-color: rgba(0, 0, 0, 0.2);
}
::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}
::-webkit-scrollbar-corner {
  background-color: transparent;
}

.ant-image-preview-body,
.ant-image-preview-mask {
  background-color: rgb(245, 245, 251) !important;
}
