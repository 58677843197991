@import '~antd/dist/antd.css';

body {
  font-family: 'Rubik', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.centralized-modal {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 80px;
  align-items: center;
}

.modal-text {
  color: #767676;
  max-width: 400px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.content-span {
  overflow: auto;
  height: calc(100vh - 80px);
  margin-top: 80px;
}

.ant-popover {
  z-index: 1071;
}

.onboarding-tooltip-overlay .ant-popover-arrow {
  border-top-color: #303030 !important;
  border-left-color: #303030 !important;
}

.ant-tooltip.modal-only {
  z-index: 3000 !important;
}

.ant-popover-no-padding > div > div > div {
  padding: 8px;
  padding: 0;
}

.remove-horizontal-scroll .ant-table-sticky-scroll {
  display: none;
}

.current-users-table-remove-horizontal-scroll .ant-table-content {
  overflow: hidden !important;
}

/* Alignments used globally for text-editor */
.editor-align-left {
  text-align: left !important;
}
.editor-align-center {
  text-align: center !important;
}
.editor-align-right {
  text-align: right !important;
}

.ant-message {
  font-size: 1.3em !important;
}

.ant-message-notice-content {
  padding: 30px 25px;
}

.ant-message .anticon {
  font-size: 1.4em !important;
}

.subMenuDiscipline {
  max-height: 50vh;
  width: 350px;
  overflow-y: auto;
}

.submenucls > ul {
  padding: 10px !important;
}

.submenucls > ul > li {
  margin: 0 !important;
}

.help-stuck-popconfirm-overlay .ant-popover-buttons {
  text-align: center !important;
}

.state-standard-filter-investigation-list-header > ul {
  height: 400px;
  width: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}

.state-standard-filter-investigation-list-header > ul > li {
  width: 380px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.library-filters-list-box > ul {
  max-height: 400px;
  max-width: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}

.library-filters-list-box-no-width > ul {
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}

.ant-message {
  z-index: 1101;
}
